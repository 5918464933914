import React from 'react';

import Header from './header';
import Footer from './footer';

import "jquery/dist/jquery.min.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "popper.js/dist/popper.min";
import '../styles/index.scss';

const Layout = (props) => {
    return (
        <div className="layout-container">
            <div className="layout-content">
            <Header />
            {props.children}
            </div>
            <Footer />
        </div>
    )
};

export default Layout;