import React from 'react';
import { Link } from 'react-scroll';

const Footer = () => {
    return (
        <footer>
            <Link activeClass="active" className="footer-link" to="slider" offset={-70} spy={true} smooth={true} duration={1000}>
                  HOME
            </Link>
            <Link activeClass="active" className="footer-link" to="about" offset={-70} spy={true} smooth={true} duration={1000}>
                  ABOUT
            </Link>
            <Link activeClass="active" className="footer-link" to="patients" offset={-70} spy={true} smooth={true} duration={1000}>
                  PATIENTS
            </Link>
            <Link activeClass="active" className="footer-link" to="sleep_disorders" offset={-70} spy={true} smooth={true} duration={1000}>
                  SLEEP DISORDERS
            </Link>
            <Link activeClass="active" className="footer-link" to="physicians" offset={-70} spy={true} smooth={true} duration={1000}>
                  PHYSICIANS
            </Link>
        </footer>
    )
}

export default Footer;